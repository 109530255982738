import React, { FC } from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { mobile, tablet } from 'styles/breakpoints';

import 'swiper/css';
import 'swiper/css/pagination';
import Text from 'components/Text';
import PrimaryButton from 'components/PrimaryButton';

import Stars from 'assets/icons/reviews-stars.svg';

interface ReviewProps {
  title: string;
  time: string;
  name: string;
  review: string;
}

interface ReviewsCardsListProps {
  title: string;
  list: ReviewProps[];
  color?: string;
  height?: string;
}

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.048rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const SwiperStyled = styled(Swiper)<{
  color?: string;
}>`
  margin-top: 2.375rem;

  .swiper-slide {
    height: auto;
    width: 21.4375rem !important;
    border-radius: 1rem;
    @media ${mobile} {
      height: 100%;
    }
  }

  .swiper-pagination {
    bottom: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #22222226;

    &-active {
      background-color: ${({ color }) => color};
    }
  }

  .swiper-wrapper {
    margin-bottom: 3.375rem;
  }

  .swiper-pagination-bullet-active {
    width: 8px;
  }

  @media ${tablet} {
    margin-top: 1.5rem;

    .swiper-wrapper {
      margin-bottom: 2.875rem;
    }
  }
`;

const ReviewsCardsList: FC<ReviewsCardsListProps> = ({
  title,
  list,
  color,
  height,
}) => (
  <Container>
    <Title>{title}</Title>
    <InnerContainer>
      <SwiperStyled
        color={color}
        modules={[Pagination]}
        pagination={{ clickable: true }}
        allowTouchMove
        effect="slide"
        slidesPerView={'auto'}
        spaceBetween={24}
      >
        {list.map((item, index) => (
          <SwiperSlide key={index}>
            <Card>
              <CardTop>
                <CardTopInner>
                  <CardTitle>{item.title}</CardTitle>
                  <Time>{item.time}</Time>
                </CardTopInner>
                <CardTopInner>
                  <Stars />
                  <Time>{item.name}</Time>
                </CardTopInner>
              </CardTop>
              <Review height={height}>{item.review}</Review>
            </Card>
          </SwiperSlide>
        ))}
      </SwiperStyled>
    </InnerContainer>
  </Container>
);

export default ReviewsCardsList;

const Container = styled.div<{ bgColor?: string }>`
  padding: 3.5rem 1rem;
  width: 100%;
  margin: 0 auto;
  background: ${({ bgColor }) => (bgColor ? bgColor : '#fff')};
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const Card = styled.section`
  display: flex;
  width: 21.4375rem;
  padding: 0.75rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #f9f9f9;
`;

const CardTitle = styled(Text)`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.018rem;
`;

const Time = styled(Text)`
  color: #4d4d4d;
  text-align: right;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.015rem;
`;

const Review = styled(Text)<{ height?: string }>`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.018rem;
  height: ${({ height }) => (height ? height : '6.375rem')};
`;

const CardTop = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  width: 100%;
`;
const CardTopInner = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InnerContainer = styled.div`
  max-width: 67.3rem;
  width: 100%;
  margin: 0 auto;
`;

const StyledButton = styled(PrimaryButton)`
  display: flex;
  max-width: 28.75rem;
  width: 100%;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  margin: 3rem auto 0;
  span {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: -0.016rem;
  }
  @media ${tablet} {
    margin-top: 1.5rem;
  }
`;
