import React, { FC } from 'react';

import styled from 'styled-components';
import { mobile, smMobile, tablet } from 'styles/breakpoints';
import { Reviews } from 'components';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import CardList from '../CardList';
import List from '../List';
import Disclaimer from 'components/Disclaimer';
import BannerOne from '../BannerOne';
import CardListWithSubtitles from '../CardListWithSubtitles';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import ImageTextCheckBoxSection from '../ImageTextCheckBoxSection';
import ImageGrid from '../ImageGrid';
import NumberedList2 from '../NumberedList2';
import ReviewsCardsList from '../ReviewCardsList';
import UpgradeDisclaimer from '../UpgradeDisclaimer';

interface StepTitle {
  text: string;
  status: string;
}

interface PaymentStepsProps {
  firstStepTitle: StepTitle;
  secondStepTitle: StepTitle;
  thirdStepTitle: StepTitle;
  optionalStepTitle?: StepTitle;
  hasOptionalStep?: boolean;
}

interface BannerOneProps {
  eBookTitle: string;
  eBookLabel: string;
  paperbackTitle: string;
  paperbackLabel: string;
  iconUrl: string;
}

interface ImageTextCheckBoxSectionProps {
  image: string;
  title: string;
  mainColor: string;
  checkBoxes: {
    items: Array<{
      title: string;
      text: string;
    }>;
  };
}

interface planSectionNewProps {
  bgHex: string;
  imgUrl: string;
  oneTimeText: string;
  originalPrice: string;
  only: string;
  additional: string;
  yesButtonTitle: string;
  noButtonTitle: string;
  mainColor: string;
}

interface ImageGridProps {
  bgColor: string;
  cardStyle: string;
  title: string;
  subtext: string;
  oneLine?: boolean;
  imageCard: Array<{
    title: string;
    url: string;
  }>;
}

interface NumberedListProps {
  title: string;
  mainColor: string;
  more: string;
  list: string[];
}

interface ReviewProps {
  title: string;
  time: string;
  name: string;
  review: string;
}

interface ReviewsSectionProps {
  title: string;
  color: string;
  height?: string;
  list: ReviewProps[];
}

interface DisclaimerProps {
  text?: string;
  cp: string;
}

interface FastingMadeEasyProps {
  paymentSteps: PaymentStepsProps;
  userHasBoughtPaperbackBook: boolean;
  planSectionNew: planSectionNewProps;
  planSectionNew2: planSectionNewProps;
  PlanSectionComponent: React.FC<any>;
  imageTextCheckBoxSection: ImageTextCheckBoxSectionProps;
  numberedList: NumberedListProps;
  reviewsSection: ReviewsSectionProps;
  imageGrid: ImageGridProps;
  disclaimer: DisclaimerProps;
  bannerOne: BannerOneProps;
}

const Container = styled.div``;

const ListContainer = styled.div`
  width: 100%;
  background-color: #f3f3f8;
  display: flex;
  justify-content: center;
`;

const ListStyledBot = styled(List)`
  max-width: 42.5rem;
`;

const FastingMadeEasy: FC<FastingMadeEasyProps> = ({
  paymentSteps,
  userHasBoughtPaperbackBook,
  planSectionNew,
  planSectionNew2,
  PlanSectionComponent,
  imageGrid,
  reviewsSection,
  numberedList,
  disclaimer,
  bannerOne,
  imageTextCheckBoxSection,
}) => {
  const PlanSectionComponentStyled = styled(PlanSectionComponent)`
    padding: 4rem 1rem;
    @media ${tablet} {
      padding: 2.25rem 1rem;
    }
  `;

  const selectedPlans = useSelector(
    (state: AppState) => state.user.selected_plans,
  );

  const hasEbookOrPaperback = selectedPlans.some(
    product =>
      product.key.includes('e-book') || product.key.includes('paper-back-book'),
  );
  return (
    <>
      {hasEbookOrPaperback && (
        <BannerOne
          {...bannerOne}
          boughtPaperback={userHasBoughtPaperbackBook}
        />
      )}
      <PaymentSteps
        {...paymentSteps}
        hasOptionalStep={userHasBoughtPaperbackBook}
      />

      <ImageTextCheckBoxSection {...imageTextCheckBoxSection} />
      <PlanSectionComponentStyled {...planSectionNew} />
      <ImageGrid {...imageGrid} />
      <NumberedList2 {...numberedList} />
      <PlanSectionComponentStyled {...planSectionNew2} />
      <ReviewsCardsList {...reviewsSection} />
      <UpgradeDisclaimer {...disclaimer} />
    </>
  );
};

export default FastingMadeEasy;
