import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';

interface ImageGridProps {
  title: string;
  subtext: string;
  imageCard: { title: string; url: string }[];
  bgColor: string;
  cardStyle: string;
  oneLine?: boolean;
}

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 31rem;
  padding-bottom: 2.25rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
  }
`;
const StyledTitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.048rem;
  padding-bottom: 1rem;
  text-align: center;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.02rem;
  @media ${tablet} {
    font-size: 1rem;
    letter-spacing: -0.016rem;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 4rem 1rem;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const GridContainer = styled.div<{
  cols: number;
  rows: number;
  oneLine?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ cols }) => `repeat(${cols}, 1fr)`};

  grid-column-gap: 2.25rem;
  grid-row-gap: 2.25rem;
  ${({ oneLine }) => oneLine && 'grid-template-columns: repeat(4, 1fr)'};
  @media ${tablet} {
    grid-template-columns: ${({ cols }) => `repeat(${cols}, 1fr)`};
    grid-column-gap: 0.9375rem;
    grid-row-gap: 0.9375rem;
  }
`;
const GridElement = styled.div<{ cardStyle: string }>`
  width: 10.25rem;
  box-shadow: 0px 0px 1px 0px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px 0px rgba(96, 97, 112, 0.16);
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  ${({ cardStyle }) => cardStyle}
`;

const GridElementText = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

const Image = styled(DynamicImage)`
  height: 6.25rem;
`;

const ImageGrid: FC<ImageGridProps> = ({
  imageCard,
  oneLine,
  cardStyle,
  subtext,
  title,
}) => {
  const { isMobile } = useQuery();

  const renderGridElement = (item: { title: string; url: string }) => (
    <GridElement cardStyle={cardStyle}>
      <Image src={item.url} />
      <GridElementText
        dangerouslySetInnerHTML={{
          __html: item.title,
        }}
      />
    </GridElement>
  );

  const cardNumber = imageCard.length;

  const cols = isMobile ? 2 : cardNumber / 2;

  const rows = isMobile ? cardNumber / 2 : 2;

  return (
    <Container>
      <TitleContainer>
        <StyledTitle
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {subtext && <Subtitle>{subtext}</Subtitle>}
      </TitleContainer>
      <GridContainer cols={cols} rows={rows} oneLine={oneLine}>
        {imageCard.map(renderGridElement)}
      </GridContainer>
    </Container>
  );
};

export default ImageGrid;
